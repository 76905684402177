/**
 * Created by ZengFanhui on 2021/1/3 16:22
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取批次虚拟信息
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetPigDetailList (saleType = 1, pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    saleType,
    pageIndex,
    pageSize
  }
  return doHttp('/trace/pigDetail/list.action', HTTP_TYPE.GET, params)
}

/**
 * 根据日期获取当日数据
 * @param date  yyyy-MM-dd
 * @returns {*}
 */
export function apiGetDetailByDate(saleType = 1, date) {
  let params = {saleType, date}
  return doHttp('/trace/pigDetail/detailByDate.action', HTTP_TYPE.GET, params)
}

/**
 * 生成当日虚拟数据
 * @returns {*}
 */
export function apiGenerateInfo(saleType) {
  let params = {saleType}
  return doHttp('/trace/pigDetail/generate.action', HTTP_TYPE.GET, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/pigDetail/delete.action', HTTP_TYPE.GET, params)
}
