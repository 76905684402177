/**
 * Created by ZengFanhui on 2021/1/4 16:17
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取产品品类
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetSaleTypeList (pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    pageIndex,
    pageSize
  }
  return doHttp('/trace/saleType/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑产品品类
 * @param params
 * @returns {*}
 */
export function apiEditSaleType(params) {
  return doHttp('/trace/saleType/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/saleType/delete.action', HTTP_TYPE.GET, params)
}

/**
 * 获取产品品类的数据配置
 * @param id
 * @returns {*}
 */
export function apiGetDetailConfigById(id) {
  let params = {saleTypeId: id}
  return doHttp('/trace/saleType/detailConfig/get.action', HTTP_TYPE.GET, params)
}

/**
 * 更新产品品类的数据配置
 * @param id
 * @returns {*}
 */
export function apiUpdateDetailConfig(list) {
  return doHttp('/trace/saleType/detailConfig/update.action', HTTP_TYPE.POST, list)
}
