<template>
  <div>
    <mt-button class="mb15">
      <el-button @click="onGenerateBtnClick" type="primary">生成当日{{saleTypeList[Number(saleTypeIndex)].name}}数据</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-tabs v-model="saleTypeIndex" @tab-click="onSaleTypeTabClick">
      <el-tab-pane v-for="(item, index) in saleTypeList" :key="item.id" :label="item.name" :name="index.toString()">
        <el-table
          ref="visitTable"
          :data="pigDetailList"
          stripe
          :height="tableHeight"
          :maxHeight="tableHeight"
          style="width: 100%;">
          <el-table-column
            header-align="left"
            type="index"
            label="#"
            :index="formatterIndex"
            width="50">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="date"
            label="生成日期">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="dayAge"
            label="日龄">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="grain"
            label="谷物">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="soybean"
            label="大豆">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="flaxSeed"
            label="亚麻籽">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="leanMeat"
            label="瘦肉中ω-3">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="fatMeat"
            label="肥肉中ω-3">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="protein"
            label="蛋白质">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="vitaminA"
            label="维生素A">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="vitaminE"
            label="维生素E">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="selenium"
            label="硒">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            width="80"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="删除" placement="top">
                <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="pigDetailTotal"
      class="pagination">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetPigDetailList, apiGenerateInfo, apiDeleteById} from '../../api/pigDetail'
  import {apiGetSaleTypeList} from '../../api/saleType'
  import {tableMixin} from '../../mixins/table'

  export default {
    name: 'TracePigInfo',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        pigDetailList: [],
        pigDetailTotal: 0,
        saleTypeList: [{
          id: 0,
          name: ''
        }],
        saleTypeIndex: '0'
      }
    },
    created() {
      this._getSaleTypeList().then(res => {
        this._getPigDetailList()
      })
    },
    methods: {
      onSaleTypeTabClick(val) {
        this.saleTypeIndex = val.index
        this.pageIndex = 1
        this._getPigDetailList()
      },
      onGenerateBtnClick() {
        this.$confirm('如果当日已有数据，新生成数据将会覆盖旧数据，是否继续？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this._generatePigDetailInfo()
        })
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该条信息？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getPigDetailList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onRefreshBtnClick() {
        this._getPigDetailList()
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getPigDetailList(this.saleTypeList[Number(this.saleTypeIndex)].id, val)
      },
      _generatePigDetailInfo() {
        const saleType = this.saleTypeList[Number(this.saleTypeIndex)].id
        apiGenerateInfo(saleType).then(data => {
          this.pageIndex = 1
          this._getPigDetailList()
          this.$message({
            type: 'success',
            message: '当日数据生成成功'
          })
        })
      },
      _getPigDetailList(saleType = this.saleTypeList[Number(this.saleTypeIndex)].id, pageIndex = this.pageIndex) {
        apiGetPigDetailList(saleType, pageIndex).then(({data}) => {
          this.pigDetailList = data.data
          this.pigDetailTotal = data.total
        })
      },
      _getSaleTypeList() {
        return apiGetSaleTypeList(1, 999).then(({data}) => {
          const result = data.data
          let saleTypeList = result.filter(item => item.needGenerate === 1)
          this.saleTypeList = saleTypeList
          this.saleTypeIndex = '0'
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
